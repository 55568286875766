import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import WifiIcon from '@mui/icons-material/Wifi';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import axios from 'axios';

const RouterMode = () => {
  const navigate = useNavigate();
  const [hoveredLink, setHoveredLink] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const defaultColor = 'rgba(170, 170, 170, 0.8)';
  const activeColor = 'rgba(2, 190, 197, 1)';
  const [hotspotSSID, setHotspotSSID] = useState('');
  const [hotspotPassword, setHotspotPassword] = useState('');

  useEffect(() => {
    const checkRoute = async () => {
      try {
        const response = await axios.get('./ZZ_readSettingNoAuth.php?rev=1&q=7');
        setHotspotSSID(response.data['Settings']['ShieldSetting_07']);
        setHotspotPassword(response.data['Settings']['ShieldSetting_08']);
        console.log(response.data);
      } catch (error) {
        console.error('Error checking route:', error);
      }
    };
    checkRoute();
    const interval = setInterval(checkRoute, 250);
    
    return () => clearInterval(interval);
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleClose = () => {
    navigate('/login');
  };

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      bgcolor: 'transparent',
      color: 'white',
      px: { xs: 3, sm: 4, md: 0 } // Add responsive padding
    }}>
      <Box sx={{
        textAlign: 'center',
        maxWidth: '590px',
        width: '100%',
      }}>
        <Typography variant="h4" sx={{
          fontWeight: 100,
          fontSize: '48px',
          mb: '5px',
        }}>
          Congratulations! You are routing through <span style={{ color: 'rgba(2, 190, 197, 1)', fontFamily: 'Roboto-Regular' }}>Anyone</span>.
        </Typography>
        
        <Box sx={{
          width: '150px',
          height: '1px',
          bgcolor: '#007275',
          mx: 'auto',
          my: '10px',
        }} />

<Paper sx={{
        bgcolor: '#0A121EFF',
        border: '1px solid #007275',
        p: { xs: 3, sm: 4 },
        my: 4,
        color: 'white',
        maxWidth: '400px',
        mx: 'auto'
      }}>
        <Typography sx={{ mb: 3, fontSize: '18px', textAlign: 'center' }}>
          You can connect using the details below:
        </Typography>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          gap: 3,
          alignItems: 'center' 
        }}>
          <Box sx={{ 
            display: 'flex', 
            gap: 2,
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <Typography sx={{ 
              color: '#aaa', 
              display: 'flex', 
              alignItems: 'center', 
              gap: 1,
              justifyContent: 'center'
            }}>
              <WifiIcon sx={{ color: 'rgba(2, 190, 197, 1)' }} />
              SSID:
              <Typography component="span" sx={{ color: 'rgba(2, 190, 197, 1)' }}>
                {hotspotSSID}
              </Typography>
            </Typography>
          </Box>
          <Box sx={{ 
            display: 'flex', 
            gap: 2,
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <Typography sx={{ 
              color: '#aaa', 
              display: 'flex', 
              alignItems: 'center', 
              gap: 1,
              justifyContent: 'center'
            }}>
              <VpnKeyIcon sx={{ color: 'rgba(2, 190, 197, 1)' }} />
              Password:
              <Typography component="span" sx={{ color: 'rgba(2, 190, 197, 1)' }}>
                {showPassword ? hotspotPassword : '•'.repeat(hotspotPassword.length)}
              </Typography>
              <IconButton
                onClick={togglePasswordVisibility}
                sx={{ 
                  color: '#aaa',
                  padding: '4px',
                  '&:hover': {
                    color: 'rgba(2, 190, 197, 1)'
                  }
                }}
              >
                {showPassword ? <VisibilityOffIcon sx={{ fontSize: 20 }} /> : <VisibilityIcon sx={{ fontSize: 20 }} />}
              </IconButton>
            </Typography>
          </Box>
        </Box>
      </Paper>

        <Box
          onClick={handleClose}
          onMouseEnter={() => setHoveredLink(true)}
          onMouseLeave={() => setHoveredLink(false)}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
            cursor: 'pointer',
            mt: '20px',
          }}
        >
          <Typography sx={{
            color: hoveredLink ? activeColor : defaultColor,
            fontSize: '20px',
            fontFamily: 'Roboto-Light',
            transition: 'color 0.2s',
          }}>
            Go to Relay Control Panel
          </Typography>
          <ArrowForwardIosIcon sx={{
            fontSize: 14,
            color: hoveredLink ? activeColor : defaultColor,
            transition: 'all 0.2s',
            transform: hoveredLink ? 'translateX(4px)' : 'translateX(0)',
          }} />
        </Box>
      </Box>
    </Box>
  );
};

export default RouterMode;