import React from 'react';
import { Typography, Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const InfoItemWithIcon = ({ label, value, icon }) => {
  const isRunning = value.toLowerCase().includes("running");

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" padding={'5px'}>
      <Box display="flex" alignItems="center" marginLeft={2.5}>
        <Typography variant="subtitle2" style={{ color: '#fff', marginRight: 8 }}>
          {label}:
        </Typography>
        {icon}
      </Box>
      <Typography variant="h6" style={{ color: '#03BDC5', fontSize: '18px', marginRight: 20, textAlign: 'right' }}>
        {value}
      </Typography>
    </Box>
  );
};

export default InfoItemWithIcon; 