import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
// import Settings from './pages/Settings'; // Placeholder for the Settings component
import Logs from './pages/Logs'; // Placeholder for the Logs component
import NetworkSettings from './pages/NetworkSettings';
import RelaySettings from './pages/RelaySettings';
import PasswordSettings from './pages/PasswordSettings';
import Login from './pages/Login';
import FamilySettingsPage from './pages/Family';
import UpdatePage from './pages/Update';
import ProxySettings from './pages/ProxySettings';
import RouterMode from './pages/Router';
import axios from 'axios';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { ShieldDataProvider } from './context/ShieldData'; // Correct path to ShieldData.js

const RouterProtectedRoute = ({ children, routerMode }) => {
  if (!routerMode) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

const SettingsProtectedRoute = ({ children, routerMode }) => {
  if (routerMode) {
    return <Navigate to="/router" replace />;
  }
  return children;
};

function App() {
  const [openSettings, setOpenSettings] = useState(false);
  const [initialRoute, setInitialRoute] = useState(null);
  const [isRouterMode, setIsRouterMode] = useState(false);

  useEffect(() => {
    const checkRoute = async () => {
      try {
        const response = await axios.get('./ZZ_readSettingNoAuth.php?rev=1&q=7');
        const routerMode = response.data['Settings']['ShieldSetting_29'] === 'on';
        setIsRouterMode(routerMode);
        setInitialRoute(routerMode ? '/login' : '/login');
      } catch (error) {
        console.error('Error checking route:', error);
        setInitialRoute('/login');
        setIsRouterMode(false);
      }
    };
    checkRoute();
    const interval = setInterval(checkRoute, 250);
    
    return () => clearInterval(interval);
  }, []);
 
  if (!initialRoute) return null;

  return (
    <ShieldDataProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to={initialRoute} replace />} />
          <Route path="/home" element={<Home openSettings={openSettings} setOpenSettings={setOpenSettings}/>} />
          <Route path="/login" element={<Login openSettings={openSettings} setOpenSettings={setOpenSettings}/>} />
          
          {/* Router Mode Protected Route */}
          <Route 
            path="/router" 
            element={
              <RouterProtectedRoute routerMode={isRouterMode}>
                <RouterMode openSettings={openSettings} setOpenSettings={setOpenSettings}/>
              </RouterProtectedRoute>
            } 
          />

          
          <Route path="/network-settings" element={<NetworkSettings openSettings={openSettings} setOpenSettings={setOpenSettings}/>} />
          
          {/* Settings Protected Routes */}

          <Route
            path="/logs"
            element={
              // <SettingsProtectedRoute routerMode={isRouterMode}>
                <Logs openSettings={openSettings} setOpenSettings={setOpenSettings}/>
              // </SettingsProtectedRoute>
            }
          />
          
          <Route 
            path="/relay-settings" 
            element={
              // <SettingsProtectedRoute routerMode={isRouterMode}>
                <RelaySettings openSettings={openSettings} setOpenSettings={setOpenSettings}/>
              // </SettingsProtectedRoute>
            }
          />
          <Route 
            path="/password-settings" 
            element={
              // <SettingsProtectedRoute routerMode={isRouterMode}>
                <PasswordSettings openSettings={openSettings} setOpenSettings={setOpenSettings}/>
              // </SettingsProtectedRoute>
            }
          />
          <Route 
            path="/relay-family" 
            element={
              // <SettingsProtectedRoute routerMode={isRouterMode}>
                <FamilySettingsPage openSettings={openSettings} setOpenSettings={setOpenSettings}/>
              // </SettingsProtectedRoute>
            }
          />
          <Route 
            path="/proxy-settings" 
            element={
              // <SettingsProtectedRoute routerMode={isRouterMode}>
                <ProxySettings openSettings={openSettings} setOpenSettings={setOpenSettings}/>
              // </SettingsProtectedRoute>
            }
          />
          <Route 
            path="/update" 
            element={
              // <SettingsProtectedRoute routerMode={isRouterMode}>
                <UpdatePage openSettings={openSettings} setOpenSettings={setOpenSettings}/>
              // </SettingsProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </ShieldDataProvider>
  );
}

export default App;