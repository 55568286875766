// LedDisplay.js
import React, { useState, useEffect } from "react";

const LedDisplay = ({ status, allstates }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [rotationPattern, setRotationPattern] = useState([]);
  const [staticDisplay, setStaticDisplay] = useState(false);
  const [ledColors, setLedColors] = useState({});

  const baseLogo = "../logo.svg"; // Adjust the path as necessary
  const ledPositions = ["tl", "tr", "br", "bl", "mid"];

  const updateDisplay = (status) => {
    if (!status) {
      return
    }
    if (status.toLowerCase().includes("not running")) {
      setRotationPattern(["tl", "tr", "br", "bl"]);
      setStaticDisplay(false);
      setLedColors({ all: "green" });
    } else if (status.toLowerCase().includes("running")) {
      setRotationPattern([]);
      setStaticDisplay(true);
      updateLedColorsBasedOnAllStates();
    } else if (status.toLowerCase().includes("time Error")) {
      setRotationPattern(["tl", "tr", "br", "bl"]);
      setStaticDisplay(false);
      setLedColors({ all: "red" });
    } else if (status.toLowerCase().includes("starting")) {
      setRotationPattern([]);
      setStaticDisplay(true);
      setLedColors({ tr: "red", tl: "red", bl: "red", br: "red", mid: "red" });
    } else if (status.toLowerCase().includes("restarting")) {
      setRotationPattern(["tl", "tr", "br", "bl"].reverse());
      setStaticDisplay(false);
      setLedColors({ all: "blue" });
    } else if (status.toLowerCase().includes("rebooting")) {
      setRotationPattern(["tl", "tr", "br", "bl"]);
      setStaticDisplay(false);
      setLedColors({ all: "green" });
    } else if (status.toLowerCase().includes("ctrl-c")) {
      setRotationPattern(["tl", "tr", "br", "bl"].reverse());
      setStaticDisplay(false);
      setLedColors({ all: "red" });
    } else if (status.toLowerCase().includes("drive (edit)")) {
      setRotationPattern(["tl", "tr", "br", "bl"]);
      setStaticDisplay(false);
      setLedColors({ all: "blue" });
    } else if (status.toLowerCase().includes("drive (config)")) {
      setRotationPattern(["tl", "tr", "br", "bl"]);
      setStaticDisplay(false);
      setLedColors({ all: "green" });
    } else if (status.toLowerCase().includes("updating")) {
      //TODO: Add LOGIC FOR UPDATING VPN
      setRotationPattern(["tl", "tr", "br", "bl"]);
      setStaticDisplay(false);
      setLedColors({ all: "green" });
    } else {
      setRotationPattern([]);
      setStaticDisplay(false);
      setLedColors({});
    }
  };

  const updateLedColorsBasedOnAllStates = () => {
    if (!allstates) return;
    const stateArray = allstates.split(",");
    setLedColors({
      tl: (stateArray[1] === "3" || stateArray[25] === "3") ? "green" : "red",
      tr: stateArray[2] === "3" ? "green" : "red",
      bl: stateArray[3] === "1" ? "green" : "red",
      br: stateArray[4] === "1" ? "green" : "red",
      mid: stateArray[5] === "1" ? "green" : "red",
    });
    // updateLedColorsBasedOnAllStates();
  };

  useEffect(() => {
    updateDisplay(status);
  }, [status, allstates]);

  useEffect(() => {
    if (!staticDisplay && rotationPattern.length > 0) {
      const intervalId = setInterval(() => {
        setCurrentStep((prevStep) => (prevStep + 1) % rotationPattern.length);
      }, 200);

      return () => clearInterval(intervalId);
    }
  }, [rotationPattern, staticDisplay]);

  const renderLeds = () => (
    <>
      <img
        src={baseLogo}
        alt="Base Logo"
        style={{ position: "absolute", height: "100%", zIndex: 1 }}
      />
      {rotationPattern.length > 0 &&
        rotationPattern.map(
          (pos, index) =>
            currentStep === index && (
              <img
                key={pos}
                src={`../logos/${pos}_${ledColors.all}.svg`}
                alt={`${pos} LED`}
                style={{ position: "absolute", height: "100%", zIndex: 2 }}
              />
            )
        )}
      {staticDisplay &&
        ledPositions.map((pos) => (
          <img
            key={pos}
            src={`../logos/${pos}_${ledColors[pos]}.svg`}
            alt={`${pos} LED`}
            style={{ position: "absolute", height: "100%", zIndex: 2 }}
          />
        ))}
    </>
  );

  return (
    <div
      style={{
        position: "relative",
        height: "60px",
        width: "60px",
        marginRight: 20,
        marginLeft: -14,
      }}
    >
      {renderLeds()}
    </div>
  );
};

export default LedDisplay;
