import { React, useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  Stack,
  Switch,
  FormControlLabel,
  TextField,
  Box,
  Button,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { FormControl, MenuItem, Select} from '@mui/material';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import MainContainer from "../components/MainContainer";
import { styled, useTheme } from "@mui/material";
import { fetchDataShield } from "../api/getRelayData";
import { useNavigate } from "react-router-dom";
import InfoToolTipMobile from "../components/InfoToolTipMobile";
import InfoToolTip from "../components/InfoToolTip";
import axios from "axios";
import countryList from '../data/countryList.json';

const boxStyle = {
  padding: "2rem",
  color: "#fff",
  backgroundColor: "#0A121E",
  borderRadius: 4,
  border: "1px solid #007275",
  marginBottom: "1rem", // Added margin bottom for spacing between rows
  boxShadow: "0px 0px 1px 0px #d4d4d4",
};

const CustomSelect = styled(Select)({
  color: 'white',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(0, 177, 183, 0.72)',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(0, 177, 183, 0.72)',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(0, 177, 183, 0.72)',
  },
  '& .MuiSelect-icon': {
    color: 'white',
  },
});


const textContainerStyle = {
  wordWrap: "break-word",
};

const CustomTextField = styled(TextField)({
  "& label": {
    color: "#595959", // Label color
  },
  "& label.Mui-focused": {
    color: "#595959", // Label color when the TextField is focused
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(0, 177, 183, 0.72)", // Default outline color
    },
    "&:hover fieldset": {
      borderColor: "rgba(0, 177, 183, 0.72)", // Outline color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(0, 177, 183, 0.72)", // Outline color when input is focused
      transition: "0.2s", // Remove the hover transition
    },
  },
  "& .MuiInputBase-input": {
    color: "white", // Input text color
  },
});

const NetworkSettings = ({ openSettings, setOpenSettings }) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [ethernetEnabled, setEthernetEnabled] = useState(false);
  const [wifiSSID, setWifiSSID] = useState("");
  const [wifiPassword, setWifiPassword] = useState("");
  const [apSSID, setApSSID] = useState("");
  const [apPassword, setApPassword] = useState("");
  const [openDialog, setOpenDialog] = useState(false); // State to control Dialog open/close
  const [showWifiPassword, setShowWifiPassword] = useState(false);
  const [showApPassword, setShowApPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [relayEnabled, setRelayEnabled] = useState("");
  const [enableFields, setEnableFields] = useState("");
  const [relayExe, setRelayExe] = useState("");
  const [relayRestart, setRelayRestart] = useState("");
  const [nickname, setNickname] = useState("");
  const [email, setEmail] = useState("");
  const [walletId, setWalletId] = useState("");
  const [port, setPort] = useState("");
  const [nftID, setNftID] = useState("");
  const [network, setNetwork] = useState("");
  const [password, setPassword] = useState("");
  const [wifiSSIDError, setWifiSSIDError] = useState("");
  const [wifiPasswordError, setWifiPasswordError] = useState("");
  const [apSSIDError, setApSSIDError] = useState("");
  const [apPasswordError, setApPasswordError] = useState("");
  const [vpnPurge, setVPNPurge] = useState("");
  const [proxyEth0, setProxyEth0] = useState("");
  const [proxyWlan0, setProxyWlan0] = useState("");
  const [proxyAP, setProxyAP] = useState("");
  const [jsonPost, setJsonPost] = useState("");
  const [wifiConnectEnabled, setWifiConnectEnabled] = useState(false);
  const [wifiHotspotEnabled, setWifiHotspotEnabled] = useState(false);
  const [booleanProxyAP, setBooleanProxyAP] = useState(false);
  const [isRouterMode, setIsRouterMode] = useState(false);
  const [routingOption, setRoutingOption] = useState('Global');
  const [ipPingAddress, setIpPingAddress] = useState("");

  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const extractData = async () => {
      let extractedData = await fetchDataShield();
      console.log(extractedData);
      setEthernetEnabled(
        extractedData["Settings"]["ShieldSetting_09"] === "on"
      );
      setPassword(extractedData["Settings"]["ShieldSetting_01"]);
      setNewPassword(extractedData["Settings"]["ShieldSetting_02"]);
      setNetwork(extractedData["Settings"]["ShieldSetting_03"]);
      console.log(extractedData['Settings']['ShieldSetting_25']);
      const wifiSSID = extractedData["Settings"]["ShieldSetting_29"] !== "relay" ? extractedData["Settings"]["ShieldSetting_25"] : extractedData["Settings"]["ShieldSetting_05"];
      console.log(wifiSSID);
      setWifiSSID(wifiSSID);
      const wifiPassword = extractedData["Settings"]["ShieldSetting_29"] !== "relay" ? extractedData["Settings"]["ShieldSetting_26"] : extractedData["Settings"]["ShieldSetting_06"];
      console.log(wifiPassword);
      setWifiPassword(wifiPassword);
      setApSSID(extractedData["Settings"]["ShieldSetting_07"]);
      setApPassword(extractedData["Settings"]["ShieldSetting_08"]);
      setRelayEnabled(extractedData["Settings"]["ShieldSetting_10"]);
      setEnableFields(extractedData["Settings"]["ShieldSetting_10"]);
      setRelayExe(extractedData["Settings"]["ShieldSetting_11"]);
      setRelayRestart(extractedData["Settings"]["ShieldSetting_12"]);
      setNickname(extractedData["Settings"]["ShieldSetting_13"]);
      setEmail(extractedData["Settings"]["ShieldSetting_14"]);
      setWalletId(extractedData["Settings"]["ShieldSetting_15"]);
      setPort(extractedData["Settings"]["ShieldSetting_16"]);
      setVPNPurge(extractedData["Settings"]["ShieldSetting_17"]);
      setProxyEth0(extractedData["Settings"]["ShieldSetting_18"]);
      setProxyWlan0(extractedData["Settings"]["ShieldSetting_19"]);
      setProxyAP(extractedData["Settings"]["ShieldSetting_20"]);
      setJsonPost(extractedData["Settings"]["ShieldSetting_21"]);
      setNftID(extractedData["Settings"]["ShieldSetting_22"]);
      setWifiConnectEnabled(
        extractedData["Settings"]["ShieldSetting_03"] === "on" &&
          extractedData["Settings"]["ShieldSetting_04"] === "join"
      );
      setWifiHotspotEnabled(
        extractedData["Settings"]["ShieldSetting_03"] === "on" &&
          extractedData["Settings"]["ShieldSetting_04"] === "ap"
      );
      setBooleanProxyAP(extractedData["Settings"]["ShieldSetting_20"] === "on");
      setIsRouterMode(extractedData["Settings"]["ShieldSetting_29"] !== "relay");

      // Find which region contains the country code
      const countryCode = extractedData["Country"];
      let selectedRegion = 'Global';
      
      if (countryCode) {
        Object.entries(countryList.regions).forEach(([region, codes]) => {
          if (codes.includes(countryCode)) {
            selectedRegion = codes.join(',');
          }
        });
      }

      setRoutingOption(selectedRegion);
      setIpPingAddress(extractedData["Settings"]["ShieldSetting_30"]);
      setIsLoading(false);
    };

    extractData();
  }, []);

  const containsIllegalCharacters = (value) => {
    const illegalCharacters = ["#", "'", "\\", "=", "´", "*", " "];
    return illegalCharacters.some((char) => value.includes(char));
  };

  const saveRequestParams = () => {
    let params = new URLSearchParams();

    const addParam = (key, value) => {
      if (value !== "") {
        params.append(key, value);
      }
    };

    const networkSetting =
      wifiConnectEnabled || wifiHotspotEnabled ? "on" : "off";
    addParam("03", networkSetting);

    const wifiSetting = wifiConnectEnabled
      ? "join"
      : wifiHotspotEnabled
      ? "ap"
      : "off";

    addParam("01", password);
    if (newPassword !== "") {
      addParam("02", newPassword);
    }

    addParam("04", wifiSetting);
    addParam("05", wifiSSID);
    addParam("06", wifiPassword);
    addParam("07", apSSID);
    addParam("08", apPassword);
    addParam("10", relayEnabled);
    addParam("11", relayExe);
    addParam("12", relayRestart);
    addParam("13", nickname);
    addParam("14", email);

    if (walletId !== "") {
      addParam("15", walletId);
    }

    addParam("16", port);
    addParam("09", ethernetEnabled ? "on" : "off");
    addParam("17", vpnPurge);
    addParam("18", proxyEth0);
    addParam("19", proxyWlan0);
    addParam("20", proxyAP);
    addParam("21", jsonPost);
    addParam("22", nftID);
    addParam("30", ipPingAddress);

    if (isRouterMode) {
      addParam("country", routingOption);
    }

    return params;
  };

  const createRequestParams = () => {
    let params = new URLSearchParams();
    params.append("09", ethernetEnabled ? "on" : "off");
    params.append(
      "04",
      wifiConnectEnabled ? "join" : wifiHotspotEnabled ? "ap" : "off"
    );
    params.append("05", wifiSSID);
    params.append("06", wifiPassword);
    params.append("07", apSSID);
    params.append("08", apPassword);
    return params;
  };

  const handleSave = async (e) => {
    e.preventDefault();

    setWifiSSIDError("");
    setWifiPasswordError("");
    setApSSIDError("");
    setApPasswordError("");

    // Validate inputs for illegal characters
    let isValid = true;
    if (containsIllegalCharacters(wifiSSID)) {
      setWifiSSIDError("Illegal characters detected (#, ', \\, =, ´, *)");
      isValid = false;
      setOpenDialog(false);
    }
    if (containsIllegalCharacters(wifiPassword)) {
      setWifiPasswordError("Illegal characters detected (#, ', \\, =, ´, *)");
      isValid = false;
      setOpenDialog(false);
    }
    if (containsIllegalCharacters(apSSID)) {
      setApSSIDError("Illegal characters detected (#, ', \\, =, ´, *)");
      isValid = false;
      setOpenDialog(false);
    }
    if (containsIllegalCharacters(apPassword)) {
      setApPasswordError("Illegal characters detected (#, ', \\, =, ´, *)");
      isValid = false;
      setOpenDialog(false);
    }
    if (wifiSSID.length > 32) {
      setWifiSSIDError(
        "Please make sure WiFi SSID is not longer than 32 characters"
      );
      isValid = false;
      setOpenDialog(false);
    }
    if (wifiPassword.length > 32) {
      setWifiPasswordError(
        "Please make sure WiFi Password is not longer than 32 characters"
      );
      isValid = false;
      setOpenDialog(false);
    }
    if (apSSID.length > 32) {
      setApSSIDError(
        "Please make sure AP SSID is not longer than 32 characters"
      );
      isValid = false;
      setOpenDialog(false);
    }
    if (apPassword.length > 32) {
      setApPasswordError(
        "Please make sure AP Password is not longer than 32 characters"
      );
      isValid = false;
      setOpenDialog(false);
    }

    if (!isValid) {
      return;
    }

    const params = saveRequestParams();
    // console.log(params.toString());
    try {
      const response = await axios.get(
        `./ZZ_shieldajax.php?rev=1&q=5&${params.toString()}`
      );

      if (isRouterMode) {
        const response = await axios.get(
          `./ZZ_shieldajax.php?rev=1&q=13&${params.toString()}`
        );
      };
      setOpenDialog(false);
      // handleReboot();
    } catch (error) {
      if (error.response) {
        console.error("Error:", error.response.data);
        if (error.response.status === 401) {
          // Handle unauthorized error specifically
          navigate("/");
        } else {
          // Handle other errors
          throw error;
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.error("Error:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error:", error.message);
      }
    }
  };

  const handleApplyClick = () => {
    setOpenDialog(true); // Open the dialog
  };

  const handleDialogClose = () => {
    setOpenDialog(false); // Close the dialog
  };

  const handleReboot = async () => {
    try {
      const res = await axios.get(`./ZZ_shieldajax.php?rev=1&q=12`);
      try {
        // Assuming '1' is the query parameter that triggers a reboot
        const response = await axios.get("./ZZ_shieldajax.php", {
          params: {
            rev: "1",
            q: "1",
          },
        });
        if (newPassword !== "") {
          try {
            const logout = await axios.get(`./ZZ_logout.php`);
            localStorage.removeItem("cacheCleared");
            navigate("/");
          } catch (error) {}
        }
        window.location.reload();
      } catch (error) {
        console.error("Reboot failed:", error);
      }
    } catch (error) {
      console.error("failed");
    }
  };

  const handleWifiConnectToggleChange = (event) => {
    const newValue = event.target.checked;
    setWifiConnectEnabled(newValue);
    if (newValue && wifiHotspotEnabled) {
      setWifiHotspotEnabled(false);
    }
  };

  const handleWifiHotspotToggleChange = (event) => {
    const newValue = event.target.checked;
    setWifiHotspotEnabled(newValue);
    if (newValue && wifiConnectEnabled) {
      setWifiConnectEnabled(false);
    }
  };

  if (isLoading) {
    return (
      <MainContainer
        title="Loading..."
        openSettings={openSettings}
        setOpenSettings={setOpenSettings}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <CircularProgress sx={{ color: "#03BDC5" }} />
        </Box>
      </MainContainer>
    );
  }

  return (
    <MainContainer
      title="My Dashboard"
      openSettings={openSettings}
      setOpenSettings={setOpenSettings}
    >
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#0A121E",
            color: "#fff",
            borderRadius: 2,
            border: "1px solid #007275",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">{"Save Changes?"}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ color: "#fff" }}
          >
            Saving changes requires a reboot to apply. Do you want to proceed with 
            saving settings?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} sx={{ color: "#03BDC5" }}>
            Cancel
          </Button>
          <Button onClick={handleSave} sx={{ color: "#03BDC5" }}>
            Save
          </Button>
          {/* <Button onClick={handleSubmit} sx={{ color: "#03BDC5" }} autoFocus>
            Apply and Reboot
          </Button> */}
        </DialogActions>
      </Dialog>

      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            style={{ color: "#ffffff" }}
            marginBottom={0}
          >
            Network Settings
          </Typography>
          {!isSmallScreen && !isMobileScreen && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                onClick={handleApplyClick}
                sx={{
                  bgcolor: "#03BDC5",
                  marginLeft: "1rem",
                  height: "40px",
                  width: "120px",
                }}
              >
                Apply
              </Button>
            </Box>
          )}
        </Box>
        {!isMobileScreen && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
              marginTop: "1rem",
              marginBottom: "3px",
            }}
          >
            <Typography
              variant="subtitle1"
              gutterBottom
              style={{ color: "#aaa" }}
              sx={{
                marginRight: "1rem",
                lineHeight: "1.4",
              }}
            >
              Change your relay network settings here. <br />
              Click 'Apply' and reboot the unit to apply.
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-start",
                flexDirection: "row",
                textAlign: "right",
                width: "50%",
                marginLeft: "auto",
              }}
            >
              <Typography
                sx={{
                  color: "#03BDC5",
                  width: "99%",
                  textAlign: "left",
                  lineHeight: "1.4",
                }}
              >
                Current Mode:
                <span style={{ color: "#aaa", textAlign: "left" }}>
                  {wifiConnectEnabled && (
                    <>
                      Connect <br /> Wi-Fi interface is active as a client,
                      connecting to available networks.
                      <InfoToolTip tooltipContent="Change to Disabled or Hotspot mode to change functionality." />
                    </>
                  )}
                  {wifiHotspotEnabled && (
                    <>
                      Hotspot <br /> Wi-Fi interface is active as an access
                      point. Other devices can connect to it.
                      <InfoToolTip tooltipContent="Change to Disabled or Connect mode to change functionality." />
                    </>
                  )}
                  {!wifiConnectEnabled && !wifiHotspotEnabled && (
                    <>
                      Disabled <br /> Wi-Fi interface is disabled.
                      <InfoToolTip tooltipContent="Change to Connect or Hotspot mode to activate the network interface as a client or access point." />
                    </>
                  )}
                </span>
              </Typography>
            </Box>
          </Box>
        )}

        {isMobileScreen && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "left",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="subtitle1"
              gutterBottom
              style={{ color: "#aaa" }}
              marginTop={"7px"}
              marginRight={"1rem"}
            >
              Change your relay network settings here. Click 'Apply' and reboot
              the unit to apply.
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "left",
                alignContent: "left",
                flexDirection: "column",
              }}
            >
              <Typography sx={{ color: "#03BDC5", textAlign: "left" }}>
                Current Mode:
                <span style={{ color: "#aaa" }}>
                  {wifiConnectEnabled && (
                    <>
                      Connect <br /> Wi-Fi interface is active as a client,
                      connecting to available networks.
                      <InfoToolTipMobile tooltipContent="Change to Disabled or Hotspot mode to change functionality." />
                    </>
                  )}
                  {wifiHotspotEnabled && (
                    <>
                      Hotspot <br /> Wi-Fi interface is active as an access
                      point. Other devices can connect to it.
                      <InfoToolTipMobile tooltipContent="Change to Disabled or Connect mode to change functionality." />
                    </>
                  )}
                  {!wifiConnectEnabled && !wifiHotspotEnabled && (
                    <>
                      Disabled <br /> Wi-Fi interface is disabled.
                      <InfoToolTipMobile tooltipContent="Change to Connect or Hotspot mode to activate the network interface as a client or access point." />
                    </>
                  )}
                </span>
              </Typography>
            </Box>
          </Box>
        )}

        {isMobileScreen && (
          <Box
            sx={{
              position: "fixed",
              zIndex: 2,
              bottom: 16,
              left: 0,
              right: 0,
              margin: "auto",
              width: "100%",
              maxWidth: "fit-content",
              backgroundColor: "#0a121e",
              borderRadius: 2,
              boxShadow: 3,
              border: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              onClick={handleApplyClick}
              sx={{ bgcolor: "#03BDC5", height: "40px", width: "250px" }}
            >
              Apply
            </Button>
          </Box>
        )}

        {isSmallScreen && !isMobileScreen && (
          <Box
            sx={{
              margin: "auto",
              width: "auto",
              maxWidth: "fit-content",
              borderRadius: 2,
              boxShadow: 3,
              mb: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <Divider
                flexItem
                orientation="vertical"
                sx={{ mx: 0.5, my: 1, backgroundColor: "teal" }}
              /> */}
              {/* <Stack
                marginLeft={2}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <Typography sx={{ color: "#aaa" }}>Ethernet</Typography>
                <FormControlLabel
                  control={
                    <Switch
                      checked={ethernetEnabled}
                      onChange={(event) =>
                        setEthernetEnabled(event.target.checked)
                      }
                      color="primary"
                    />
                  }
                  labelPlacement="start"
                  sx={{
                    justifyContent: "start",
                    "& .MuiSwitch-track": {
                      backgroundColor: "#b1e0f1ff",
                    },
                    "& .MuiSwitch-switchBase.Mui-checked": {
                      color: "#03BDC5",
                      "& + .MuiSwitch-track": {
                        backgroundColor: "#03BDC5",
                      },
                    },
                  }}
                />
              </Stack> */}
              <Button
                variant="contained"
                onClick={handleApplyClick}
                sx={{
                  bgcolor: "#03BDC5",
                  marginLeft: "1rem",
                  height: "40px",
                  width: "120px",
                }}
              >
                Apply
              </Button>
            </Box>
          </Box>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Paper style={boxStyle}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h5" gutterBottom>
                  Wi-Fi Connect
                </Typography>
                {!isRouterMode && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={wifiConnectEnabled}
                        onChange={handleWifiConnectToggleChange}
                        color="primary"
                      />
                    }
                    labelPlacement="start"
                    sx={{
                      justifyContent: "start",
                      "& .MuiSwitch-track": {
                        backgroundColor: "#b1e0f1ff",
                      },
                      "& .MuiSwitch-switchBase.Mui-checked": {
                        color: "#03BDC5",
                        "& + .MuiSwitch-track": {
                          backgroundColor: "#03BDC5",
                        },
                      },
                    }}
                  />
                )}
              </Box>
              <Typography variant="subtitle1" sx={{ color: "#aaa" }}>
                Your existing WiFi details for running a relay
              </Typography>
              <Box sx={{ margin: "auto" }}>
                <CustomTextField
                  fullWidth
                  label="WI-FI SSID"
                  variant="outlined"
                  margin="normal"
                  inputProps={{ maxLength: 32 }}
                  value={wifiSSID}
                  error={!!wifiSSIDError}
                  helperText={wifiSSIDError}
                  onChange={(e) => setWifiSSID(e.target.value)}
                />
                <CustomTextField
                  fullWidth
                  label="WI-FI PASSWORD"
                  inputProps={{ maxLength: 32 }}
                  type={showWifiPassword ? "text" : "password"}
                  variant="outlined"
                  margin="normal"
                  value={wifiPassword}
                  error={!!wifiPasswordError}
                  helperText={wifiPasswordError}
                  onChange={(e) => setWifiPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowWifiPassword(!showWifiPassword)}
                          edge="end"
                          sx={{ color: "#aaa" }}
                        >
                          {showWifiPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: { color: "white" },
                  }}
                />
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Paper style={boxStyle}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h5" gutterBottom>
                  Wi-Fi Hotspot
                </Typography>

                <FormControlLabel
                  control={
                    <Switch
                      checked={wifiHotspotEnabled}
                      onChange={handleWifiHotspotToggleChange}
                      color="primary"
                    />
                  }
                  labelPlacement="start"
                  sx={{
                    justifyContent: "start",
                    "& .MuiSwitch-track": {
                      backgroundColor: "#b1e0f1ff",
                    },
                    "& .MuiSwitch-switchBase.Mui-checked": {
                      color: "#03BDC5",
                      "& + .MuiSwitch-track": {
                        backgroundColor: "#03BDC5",
                      },
                    },
                  }}
                />
              </Box>
              <Typography variant="subtitle1" sx={{ color: "#aaa" }}>
                Set login details for the pairing mode WiFi
              </Typography>
              <Box margin="auto">
                <CustomTextField
                  fullWidth
                  label="AP SSID"
                  variant="outlined"
                  inputProps={{ maxLength: 32 }}
                  margin="normal"
                  value={apSSID}
                  error={!!apSSIDError}
                  helperText={apSSIDError}
                  onChange={(e) => setApSSID(e.target.value)}
                />
                <CustomTextField
                  fullWidth
                  label="AP PASSWORD"
                  inputProps={{ maxLength: 32 }}
                  type={showApPassword ? "text" : "password"}
                  variant="outlined"
                  margin="normal"
                  value={apPassword}
                  onChange={(e) => setApPassword(e.target.value)}
                  error={!!apPasswordError}
                  helperText={apPasswordError}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowApPassword(!showApPassword)}
                          edge="end"
                          sx={{ color: "#aaa" }}
                        >
                          {showApPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: { color: "white" },
                  }}
                />
              </Box>
            </Paper>
          </Grid>
          {isRouterMode && (
          <Grid item xs={6}>
            <Paper style={boxStyle}>
              <Typography variant="h5" gutterBottom>
                Routing Settings
              </Typography>
              <Typography variant="subtitle1" sx={{ color: "#aaa" }}>
                The location of the nodes for your circuit
              </Typography>
              <Box sx={{ mt: 2 }}>
                <FormControl fullWidth>
                  <CustomSelect
                    value={routingOption}
                    onChange={(e) => setRoutingOption(e.target.value)}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          bgcolor: '#0A121E',
                          border: '1px solid #007275',
                          maxHeight: '400px',
                          '& .MuiMenuItem-root': {
                            color: 'white',
                            '&:hover': {
                              bgcolor: 'rgba(3, 189, 197, 0.08)',
                            },
                            '&.Mui-selected': {
                              bgcolor: 'rgba(3, 189, 197, 0.16)',
                              '&:hover': {
                                bgcolor: 'rgba(3, 189, 197, 0.24)',
                              },
                            },
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value="Global">Global</MenuItem>
                    {Object.entries(countryList.regions).map(([region, codes]) => (
                      <MenuItem key={region} value={codes.join(',')}>
                        {region.replace('_', ' ')}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                </FormControl>
              </Box>
            </Paper>
          </Grid>
          )}

        </Grid>
      </Box>
    </MainContainer>
  );
};

export default NetworkSettings;
