import React, { useState } from "react";
import {
  Typography,
  Grid,
  Paper,
  Box,
  Button,
  useMediaQuery,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import MainContainer from "../components/MainContainer";
import axios from "axios";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const boxStyle = {
  padding: "2rem",
  color: "#fff",
  backgroundColor: "#0A121E",
  borderRadius: 4,
  border: "1px solid #007275",
  marginBottom: "1rem",
  boxShadow: "0px 0px 1px 0px #d4d4d4",
  minHeight: "295px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const UploadBox = styled(Box)(({ theme, isDragging }) => ({
  border: `2px dashed ${isDragging ? "#03BDC5" : "#007275"}`,
  padding: "2rem",
  textAlign: "center",
  color: "#aaa",
  cursor: "pointer",
  backgroundColor: isDragging ? "#1a1f2b" : "#0A121E",
  borderRadius: 4,
  transition: "background-color 0.3s ease",
  width: "100%",
  minHeight: "200px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

const UpdatePage = ({ openSettings, setOpenSettings }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width:1250px)");
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [files, setFiles] = useState([]);
  const [uploadStatus, setUploadStatus] = useState("");
  const [isDragging, setIsDragging] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [password, setPassword] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles].slice(0, 4));
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const selectedFiles = Array.from(event.dataTransfer.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles].slice(0, 4));
    setIsDragging(false);
  };

  const handleDelete = (event, index) => {
    event.stopPropagation(); // Stop the event from bubbling up
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleUpload = () => {
    if (files.length > 0) {
      setOpenDialog(true);
    }
  };

  const handleConfirmUpload = async () => {
    setIsVerifying(true);
    
    try {
      // First verify the password
      const loginFormData = new FormData();
      loginFormData.append('login_username', 'admin');
      loginFormData.append('login_password', password);

      const loginResponse = await fetch('./ZZ_login2.php', {
        method: 'POST',
        body: loginFormData,
      });

      const loginData = await loginResponse.json();

      if (!loginResponse.ok || !loginData.success) {
        setUploadStatus('Invalid password. Please try again.');
        setIsVerifying(false);
        return;
      }

      // If password is correct, proceed with upload
      setOpenDialog(false);
      setUploadStatus("Uploading...");
      setUploadProgress(0);
      
      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append(`file${index + 1}`, file);
      });
      formData.append('adminPassword', password);

      const response = await axios.post("./ZZ_uploadupdate.php?rev=1", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      });

      console.log("Upload response:", response);

      if (response.status === 200) {
        setUploadStatus("Files uploaded successfully!");
        setFiles([]);
      } else {
        setUploadStatus("Upload failed. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      setUploadStatus("An error occurred. Please try again.");
    } finally {
      setIsVerifying(false);
      setPassword("");
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <MainContainer
      title="My Dashboard"
      openSettings={openSettings}
      setOpenSettings={setOpenSettings}
    >
      <Box sx={{ width: "100%" }}>
        {/* Header */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            style={{ color: "#ffffff" }}
            marginBottom={0}
          >
            Update Relay
          </Typography>
        </Box>

        {/* Subtitle */}
        <Typography
          variant="subtitle1"
          gutterBottom
          style={{ color: "#aaa" }}
          marginBottom={2}
        >
          Upload new update files here
        </Typography>

        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={10} md={8} lg={6}>
            <Paper style={boxStyle}>
              <UploadBox
                isDragging={isDragging}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                onClick={() => document.getElementById("fileUpload").click()}
              >
                <input
                  id="fileUpload"
                  type="file"
                  accept=".zip"
                  multiple
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
                {files.length === 0 ? (
                  <>
                    <CloudUploadIcon
                      style={{
                        fontSize: 48,
                        color: "#007275",
                        marginBottom: "1rem",
                      }}
                    />
                    <Typography variant="body1" style={{ color: "#aaa" }}>
                      Drag and drop up to 4 zipped folders here or click to
                      select files
                    </Typography>
                  </>
                ) : (
                  <List sx={{ color: "#aaa", width: "100%" }}>
                    {files.map((file, index) => (
                      <ListItem key={index}>
                        <ListItemText primary={file.name} />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={(event) => handleDelete(event, index)}
                          >
                            <DeleteIcon style={{ color: "#FF5722" }} />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                )}
              </UploadBox>
              <Box
                mt={2}
                display="flex"
                flexDirection="column"
                alignItems="center"
                width="100%"
              >
                <Button
                  variant="contained"
                  sx={{ bgcolor: "#03BDC5", marginBottom: "1rem" }}
                  onClick={handleUpload}
                  disabled={files.length === 0}
                >
                  Upload Files
                </Button>
                {uploadStatus && (
                  <Typography
                    variant="body2"
                    style={{
                      color: uploadStatus.includes("successfully")
                        ? "#4CAF50"
                        : "#FF5722",
                      marginBottom: "0.5rem",
                    }}
                  >
                    {uploadStatus}
                  </Typography>
                )}
                {uploadProgress > 0 && (
                  <Box sx={{ width: "100%", marginTop: "1rem" }}>
                    <LinearProgress
                      variant="determinate"
                      value={uploadProgress}
                    />
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      align="center"
                    >
                      {`${Math.round(uploadProgress)}%`}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>

      <Dialog 
        open={openDialog} 
        onClose={() => {
          setOpenDialog(false);
          setUploadStatus("");
          setPassword("");
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#0A121E",
            color: "#fff",
            borderRadius: 2,
            border: "1px solid #007275",
            minWidth: "400px",
            padding: "1rem",
          },
        }}
      >
        <DialogTitle 
          id="alert-dialog-title"
        >
          {"Password Required"}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Enter Password"
            type={showPassword ? 'text' : 'password'}
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={!!uploadStatus && uploadStatus.includes("Invalid password")}
            helperText={uploadStatus && uploadStatus.includes("Invalid password") ? uploadStatus : ""}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleTogglePasswordVisibility}
                    edge="end"
                    sx={{ color: '#aaa' }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              marginTop: "16px",
              "& label": {
                color: "#595959",
              },
              "& label.Mui-focused": {
                color: "#595959",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "rgba(0, 177, 183, 0.72)",
                },
                "&:hover fieldset": {
                  borderColor: "rgba(0, 177, 183, 0.72)",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "rgba(0, 177, 183, 0.72)",
                },
              },
              "& .MuiInputBase-input": {
                color: "white",
                padding: "14px",
              },
            }}
          />
        </DialogContent>
        <DialogActions sx={{ padding: "20px 24px", justifyContent: "space-between" }}>
          <Button 
            onClick={() => {
              setOpenDialog(false);
              setUploadStatus("");
              setPassword("");
            }} 
            sx={{ 
              color: "#03BDC5",
              padding: "8px 16px",
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleConfirmUpload} 
            disabled={!password || isVerifying}
            sx={{ 
              color: "#03BDC5",
              padding: "8px 16px",
              marginLeft: "8px", 
            }}
          >
            {isVerifying ? (
              <CircularProgress size={24} sx={{ color: '#03BDC5' }} />
            ) : (
              "Confirm Upload"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </MainContainer>
  );
};

export default UpdatePage;
