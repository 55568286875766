import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  Divider,
  ToggleButton,
  ToggleButtonGroup,
  TextField,
  Box,
  Button,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Slider,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  List,
  ListItem,
} from "@mui/material";
import MainContainer from "../components/MainContainer";
import { styled, useTheme } from "@mui/material";
import { fetchDataShield } from "../api/getRelayData";
import axios from "axios";
import { json, useNavigate } from "react-router-dom";
import InfoToolTip from "../components/InfoToolTip";
import InfoToolTipMobile from "../components/InfoToolTipMobile";
import { useShieldData } from "../context/ShieldData";

const boxStyle = {
  padding: "2rem",
  color: "#fff",
  backgroundColor: "#0A121E",
  borderRadius: 4,
  border: "1px solid #007275",
  marginBottom: "1rem", // Added margin bottom for spacing between rows
  boxShadow: "0px 0px 1px 0px #d4d4d4",
  minHeight: "295px",
};

const CustomTextField = styled(TextField)({
  "& label": {
    color: "#595959", // Default label color
  },
  "& label.Mui-focused": {
    color: "#595959", // Label color when the TextField is focused
  },
  "& label.Mui-disabled": {
    color: "#595959", // Label color when the TextField is disabled
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(0, 177, 183, 0.72)", // Default outline color
    },
    "&:hover fieldset": {
      borderColor: "rgba(0, 177, 183, 0.72)", // Outline color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(0, 177, 183, 0.72)", // Outline color when input is focused
    },
    "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      borderColor: "#aaa", // Outline color when input is disabled
    },
  },
  "& .MuiInputBase-input": {
    color: "white", // Input text color
  },
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "#595959", // Text color when input is disabled
  },
});

const RelaySettings = ({ openSettings, setOpenSettings }) => {
  const [relayEnabled, setRelayEnabled] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [networkSetting, setNetworkSetting] = useState("");

  const [accountingMax, setAccountingMax] = useState(500);
  const [bandWidthRate, setBandWidthRate] = useState(500);
  const [accountingStart, setAccountingStart] = useState("month");

  const [enableFields, setEnableFields] = useState("");
  const [relayExe, setRelayExe] = useState("");
  const [relayRestart, setRelayRestart] = useState("");
  const [nickname, setNickname] = useState("");
  const [email, setEmail] = useState("");
  const [walletId, setWalletId] = useState("");
  const [port, setPort] = useState("");
  const [nftID, setNftID] = useState("");
  const theme = useTheme();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width:1250px)");
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoading, setIsLoading] = useState(true);
  const [wifiMode, setWifiMode] = useState("");
  const [ethernetEnabled, setEthernetEnabled] = useState(false);
  const [wifiSSID, setWifiSSID] = useState("");
  const [wifiPassword, setWifiPassword] = useState("");
  const [apSSID, setApSSID] = useState("");
  const [apPassword, setApPassword] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isAccountingEnabled, setIsAccountingEnabled] = useState("enable");
  const [allStates, setAllStates] = useState([]);
  const [ipPingAddress, setIpPingAddress] = useState("");

  const [vpnPurge, setVPNPurge] = useState("");
  const [proxyEth0, setProxyEth0] = useState("");
  const [proxyWlan0, setProxyWlan0] = useState("");
  const [proxyAP, setProxyAP] = useState("");

  const [relayExeError, setRelayExeError] = useState("");
  const [relayRestartError, setRelayRestartError] = useState("");
  const [ipPingAddressError, setIpPingAddressError] = useState("");
  const [jsonPostError, setJsonPostError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [walletIdError, setWalletIdError] = useState("");
  const [portError, setPortError] = useState("");
  const [nftIDError, setNftIDError] = useState("");
  const [jsonPost, setJsonPost] = useState("");

  const [checkboxState, setCheckboxState] = useState(false);
  const [offWarning, setOffWarning] = useState();

  // TODO: Check this support for Ethereum addresses

  const isValidEthAddress = (address) => address === "" || /^0x[a-fA-F0-9]{40}$/.test(address);
  const isValidEmail = (email) =>
    email === "" || /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
  const isValidPort = (port) =>
    /^\d+$/.test(port) &&
    port >= 1 &&
    port <= 65535 &&
    port !== '80' &&
    port !== '8080' &&
    port !== '22' &&
    port !== '53' &&
    port !== '9050' &&
    port !== '9051';
  const isValidNftID = (nftID) =>
    nftID === "" || /^([1-9]\d{0,2}|1000)$/.test(nftID);
  const isValidNickname = (nickname) => /^[a-zA-Z0-9]{1,19}$/.test(nickname);
  const containsIllegalCharacters = (value) => {
    const illegalCharacters = ["#", "'", "\\", "=", "´", "*"];
    return illegalCharacters.some((char) => value.includes(char));
  };
  const isValidIpAddress = (address) => /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(address);
  const isRelayEnabled = enableFields === "on" || allStates[3] === "1";

  const { shieldData, error2 } = useShieldData();

  useEffect(() => {
    const extractData = async () => {
      let extractedData = await fetchDataShield();
      // Assuming extractedData has the required keys

      setRelayEnabled(
        extractedData["Settings"]["ShieldSetting_10"] ?? "UNKNOWN"
      );
      setNewPassword(extractedData["Settings"]["ShieldSetting_02"]);
      setRelayExe(extractedData["Settings"]["ShieldSetting_11"] ?? "UNKNOWN");
      setRelayRestart(
        extractedData["Settings"]["ShieldSetting_12"] ?? "UNKNOWN"
      );
      setNickname(extractedData["Settings"]["ShieldSetting_13"] ?? "UNKNOWN");
      setEmail(extractedData["Settings"]["ShieldSetting_14"] ?? "UNKNOWN");
      setWalletId(extractedData["Settings"]["ShieldSetting_15"] ?? "UNKNOWN");
      setPort(extractedData["Settings"]["ShieldSetting_16"] ?? "UNKNOWN");

      setAllStates(
        extractedData["SHIELD_allstates"]?.split(",") ?? ["UNKNOWN"]
      );

      setEnableFields(
        extractedData["Settings"]["ShieldSetting_10"] ?? "UNKNOWN"
      );

      setVPNPurge(extractedData["Settings"]["ShieldSetting_17"] ?? "UNKNOWN");
      setProxyEth0(extractedData["Settings"]["ShieldSetting_18"] ?? "UNKNOWN");
      setProxyWlan0(extractedData["Settings"]["ShieldSetting_19"] ?? "UNKNOWN");
      setProxyAP(extractedData["Settings"]["ShieldSetting_20"] ?? "UNKNOWN");
      setJsonPost(extractedData["Settings"]["ShieldSetting_21"] ?? "UNKNOWN");
      const nftID = extractedData["Settings"]["ShieldSetting_22"];
      setNftID(nftID === "0" ? "" : nftID ?? "UNKNOWN");

      setWifiMode(extractedData["Settings"]["ShieldSetting_04"] ?? "UNKNOWN");
      setEthernetEnabled(
        (extractedData["Settings"]["ShieldSetting_09"] ?? "UNKNOWN") === "on"
      );
      setWifiSSID(extractedData["Settings"]["ShieldSetting_05"] ?? "UNKNOWN");
      setWifiPassword(
        extractedData["Settings"]["ShieldSetting_06"] ?? "UNKNOWN"
      );
      setApSSID(extractedData["Settings"]["ShieldSetting_07"] ?? "UNKNOWN");
      setApPassword(extractedData["Settings"]["ShieldSetting_08"] ?? "UNKNOWN");
      setNetworkSetting(
        extractedData["Settings"]["ShieldSetting_03"] ?? "UNKNOWN"
      );
      setPassword(extractedData["Settings"]["ShieldSetting_01"] ?? "UNKNOWN");
      setAccountingMax(
        extractedData["Settings"]["AccountingMax"] === ""
          ? 15
          : Number(extractedData["Settings"]["AccountingMax"])
      );
      if (extractedData["Settings"]["AccountingStart"] !== "") {
        setAccountingStart(
          extractedData["Settings"]["AccountingStart"] ?? "UNKNOWN"
        );
      }
      setBandWidthRate(
        Number(extractedData["Settings"]["RelayBandwidthRate"] ?? 2)
      );
      setIsAccountingEnabled(
        extractedData["Settings"]["AccountingEnabled"] ?? "UNKNOWN"
      );
      setIpPingAddress(extractedData["Settings"]["ShieldSetting_30"] ?? "UNKNOWN");
      setIsLoading(false);
    };

    if (shieldData["SHIELD_state"] && shieldData["SHIELD_state"].toLowerCase().includes('running')) {
      extractData();
    }
  }, [shieldData["SHIELD_state"]]);

  useEffect(() => {
    if (!bandWidthRate || bandWidthRate < 2) {
      setBandWidthRate(2);
    }
    if (!accountingMax || accountingMax < 15) {
      setAccountingMax(15);
    }
  });

  useEffect(() => {
    setVPNPurge(checkboxState ? "on" : "off");
  }, [checkboxState]);

  useEffect(() => {
    setCheckboxState(vpnPurge === "on");
  }, [vpnPurge]);

  const saveRequestParams = () => {
    let params = new URLSearchParams();

    const addParam = (key, value) => {
      if (value !== "") {
        params.append(key, value);
      }
      if (key === '14') {
        params.append('14', value);
      }
    };

    addParam("01", password);
    if (newPassword !== "") {
      params.append("02", newPassword);
    }
    addParam("03", networkSetting);
    addParam("10", relayEnabled);
    addParam("11", relayExe);
    addParam("12", relayRestart);
    addParam("13", nickname);
    addParam("14", email);
    console.log("Email: ", email);
    if (walletId !== "") {
      params.append("15", walletId);
    }
    addParam("16", port);
    addParam("09", ethernetEnabled ? "on" : "off");
    addParam("04", wifiMode);
    addParam("05", wifiSSID);
    addParam("06", wifiPassword);
    addParam("07", apSSID);
    addParam("08", apPassword);
    addParam("17", vpnPurge);
    addParam("18", proxyEth0);
    addParam("19", proxyWlan0);
    addParam("20", proxyAP);
    addParam("21", jsonPost);
    addParam("30", ipPingAddress);
    params.append("22", nftID === "" ? "0" : nftID);

    return params;
  };

  const saveRequestParamsConfig = () => {
    const addParam = (key, value) => {
      if (value !== "") {
        params.append(key, value);
      }
    };
    let params = new URLSearchParams();
    params.append("17", accountingMax);
    params.append("18", accountingStart);
    params.append("19", bandWidthRate);
    params.append("20", bandWidthRate * 1.2);
    params.append("21", isAccountingEnabled);
    addParam("13", nickname);
    addParam("14", email);
    addParam("15", walletId);
    return params;
  };

  const handleAccountingStartChange = (event) => {
    setAccountingStart(event.target.value);
  };

  const handleSliderChange = (setter, max) => (event, newValue) => {
    const value = Math.min(newValue, max); // Ensure the newValue does not exceed max
    setter(value);
  };

  const handleInputChange = (event, setter, max, min) => {
    let value = event.target.value === "" ? min : Number(event.target.value); // Convert string to number
    value = Math.min(Math.max(value, min), max); // Clamp value to the range [0, max]
    setter(value);
  };

  const handleBlur = (setter, value) => () => {
    if (value < 0) {
      setter(0);
    } else if (value > 1000) {
      setter(1000);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();

    let isValid = true;

    setRelayExeError("");
    setRelayRestartError("");
    setJsonPostError("");
    setEmailError("");
    setWalletIdError("");
    setPortError("");
    setNftIDError("");

    setError("");

    // Validate each field
    if (!isValidEthAddress(walletId)) {
      setWalletIdError("Please enter a valid Ethereum wallet address.");
      isValid = false;
      setOpenDialog(false);
    }
    if (!isValidEmail(email)) {
      setEmailError("Please enter a valid email address.");
      isValid = false;
      setOpenDialog(false);
    }
    if (!isValidPort(port)) {
      setPortError("Please enter a valid port number (1-65535 ex. 80, 8080, 22, 53, 9050, 9051).");
      isValid = false;
      setOpenDialog(false);
    }
    if (!isValidNftID(nftID)) {
      setNftIDError("Please enter a valid NFT ID (1-1000).");
      isValid = false;
      setOpenDialog(false);
    }
    if (containsIllegalCharacters(relayExe)) {
      setRelayExeError("Illegal characters detected (#, ', \\, =, ´, *)");
      isValid = false;
      setOpenDialog(false);
    }
    if (containsIllegalCharacters(relayRestart)) {
      setRelayRestartError("Illegal characters detected (#, ', \\, =, ´, *)");
      isValid = false;
      setOpenDialog(false);
    }
    if (containsIllegalCharacters(jsonPost)) {
      setJsonPostError("Illegal characters detected (#, ', \\, =, ´, *)");
      isValid = false;
      setOpenDialog(false);
    }
    if (containsIllegalCharacters(nickname)) {
      setError("Illegal characters detected (#, ', \\, =, ´, *)");
      isValid = false;
      setOpenDialog(false);
    }
    if (containsIllegalCharacters(email)) {
      setEmailError("Illegal characters detected (#, ', \\, =, ´, *)");
      isValid = false;
      setOpenDialog(false);
    }
    if (!isValidNickname(nickname)) {
      setError(
        "Nickname must be up to 19 alphanumeric characters with no spaces."
      );
      isValid = false;
      setOpenDialog(false);
    }
    if (!isValidIpAddress(ipPingAddress)) {
      setIpPingAddressError("Please enter a valid IP address.");
      isValid = false;
      setOpenDialog(false);
    }
    if (email.length > 49) {
      setEmailError("Please make sure Email is not longer than 49 characters");
      isValid = false;
      setOpenDialog(false);
    }

    if (relayRestart.length > 49) {
      setRelayRestartError(
        "Please make sure API Call Location is not longer than 49 characters"
      );
      isValid = false;
      setOpenDialog(false);
    }

    if (jsonPost.length > 49) {
      setJsonPostError(
        "Please make sure Node aunthentication endpoint is not longer than 49 characters"
      );
      isValid = false;
      setOpenDialog(false);
    }
    
    if (relayExe.length > 49) {
      setRelayExeError(
        "Please make sure Relay Exe is not longer than 49 characters"
      );
      isValid = false;
      setOpenDialog(false);
    }


    if (!isValid) {
      return;
    }

    const params = saveRequestParams();
    // console.log(params.toString());
    try {
      const response = await axios.get(
        `./ZZ_shieldajax.php?rev=1&q=5&${params.toString()}`
      );

      const configParams = saveRequestParamsConfig();

      try {
        const configResponse = await axios.get(
          `./ZZ_shieldajax.php?rev=1&q=11&${configParams.toString()}`
        );
        // console.log(JSON.stringify(configResponse.data, null, 2));
        setOpenDialog(false);
      } catch (error) {
        console.error("Error:", error);
        if (error.response) {
          console.error("Error:", error.response.data);
          if (error.response.status === 401) {
            // Handle unauthorized error specifically
            navigate("/");
          } else {
            // Handle other errors
            throw error;
          }
        } else if (error.request) {
          // The request was made but no response was received
          console.error("Error:", error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error:", error.message);
        }
      }
      // handleReboot();
    } catch (error) {
      if (error.response) {
        console.error("Error:", error.response.data);
        if (error.response.status === 401) {
          // Handle unauthorized error specifically
          navigate("/");
        } else {
          // Handle other errors
          throw error;
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.error("Error:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error:", error.message);
      }
    }
  };

  const handleApplyClick = () => {
    setOpenDialog(true); // Open the dialog
  };

  const handleDialogClose = () => {
    setOpenDialog(false); // Close the dialog
  };

  const handleToggleChange = async (e, newMode) => {
    const prev = enableFields;
    if (newMode !== null) {
      setRelayEnabled(newMode);
      if (prev === "on" && newMode === "off") {
        setOffWarning(true);
      } else {
        setOffWarning(false);
      }
    }
    if (newMode === "on") {
      setOffWarning(false);
    }
  };

  const handleToggleChangeAccounting = (event, newMode) => {
    if (newMode !== null) {
      setIsAccountingEnabled(newMode);
    }
  };

  const handleReboot = async () => {
    try {
      // Assuming '1' is the query parameter that triggers a reboot
      const response = await axios.get("./ZZ_shieldajax.php", {
        params: {
          // rev: WEB_VER_ajax, // Make sure this variable is defined and holds the correct value
          rev: "1",
          q: "1",
        },
      });
      // console.log(response.data);
      // Assuming you want to reload to home after the reboot action
      if (newPassword !== "") {
        try {
          const logout = await axios.get(`./ZZ_logout.php`);
          localStorage.removeItem("cacheCleared");
          navigate("/");
        } catch (error) {}
      }
      window.location.reload();
    } catch (error) {
      console.error("Reboot failed:", error);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    setRelayExeError("");
    setRelayRestartError("");
    setJsonPostError("");
    setEmailError("");
    setWalletIdError("");
    setPortError("");
    setNftIDError("");
    setError("");

    // Validate each field
    let isValid = true;
    if (!isValidEthAddress(walletId)) {
      setWalletIdError("Please enter a valid Ethereum wallet address.");
      isValid = false;
      setOpenDialog(false);
    }
    if (!isValidEmail(email)) {
      setEmailError("Please enter a valid email address.");
      isValid = false;
      setOpenDialog(false);
    }
    if (!isValidPort(port)) {
      setPortError("Please enter a valid port number (1-65535).");
      isValid = false;
      setOpenDialog(false);
    }
    if (!isValidNftID(nftID)) {
      setNftIDError("Please enter a valid NFT ID (1-1000).");
      isValid = false;
      setOpenDialog(false);
    }
    if (containsIllegalCharacters(relayExe)) {
      setRelayExeError("Illegal characters detected (#, ', \\, =, ´, *)");
      isValid = false;
      setOpenDialog(false);
    }
    if (containsIllegalCharacters(relayRestart)) {
      setRelayRestartError("Illegal characters detected (#, ', \\, =, ´, *)");
      isValid = false;
      setOpenDialog(false);
    }
    if (containsIllegalCharacters(jsonPost)) {
      setJsonPostError("Illegal characters detected (#, ', \\, =, ´, *)");
      isValid = false;
      setOpenDialog(false);
    }
    if (containsIllegalCharacters(nickname)) {
      setError("Illegal characters detected (#, ', \\, =, ´, *)");
      isValid = false;
      setOpenDialog(false);
    }
    if (containsIllegalCharacters(email)) {
      setEmailError("Illegal characters detected (#, ', \\, =, ´, *)");
      isValid = false;
      setOpenDialog(false);
    }

    if (!isValidNickname(nickname)) {
      setError(
        "Nickname must be up to 19 alphanumeric characters with no spaces."
      );
      isValid = false;
      setOpenDialog(false);
    }
    if (email.length > 49) {
      setEmailError("Please make sure Email is not longer than 49 characters");
      isValid = false;
      setOpenDialog(false);
    }
    if (relayRestart.length > 49) {
      setRelayRestartError(
        "Please make sure API Call Location is not longer than 49 characters"
      );
      isValid = false;
      setOpenDialog(false);
    }
    if (jsonPost.length > 49) {
      setJsonPostError(
        "Please make sure Node aunthentication endpoint is not longer than 49 characters"
      );
      isValid = false;
      setOpenDialog(false);
    }

    if (!isValid) {
      return;
    }

    const params = saveRequestParams();
    // console.log(params.toString());

    const configParams = saveRequestParamsConfig();

    try {
      const response = await axios.get(
        `./ZZ_shieldajax.php?rev=1&q=5&${params.toString()}`
      );
      try {
        const configResponse = await axios.get(
          `./ZZ_shieldajax.php?rev=1&q=11&reboot=1&${configParams.toString()}`
        );
        setOpenDialog(false);
        setTimeout(() => {
          handleReboot();
        }, 1000);
      } catch (error) {
        console.error("Error:", error);
        if (error.response) {
          console.error("Error:", error.response.data);
          if (error.response.status === 401) {
            // Handle unauthorized error specifically
            navigate("/");
          } else {
            // Handle other errors
            throw error;
          }
        } else if (error.request) {
          // The request was made but no response was received
          console.error("Error:", error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error:", error.message);
        }
      }
    } catch (error) {
      if (error.response) {
        console.error("Error:", error.response.data);
        if (error.response.status === 401) {
          // Handle unauthorized error specifically
          navigate("/");
        } else {
          // Handle other errors
          throw error;
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.error("Error:", error.request);
        // setError("Failed to update password. Please try again.");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error:", error.message);
        // setError("An error occurred. Please try again.");
      }
    }
  };

  const handlePurge = (e) => {
    setVPNPurge(e.target.checked ? "on" : "off");
  };

  if (isLoading) {
    return (
      <MainContainer
        title="Loading..."
        openSettings={openSettings}
        setOpenSettings={setOpenSettings}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <CircularProgress sx={{ color: "#03BDC5" }} />
        </Box>
      </MainContainer>
    );
  }

  return (
    <MainContainer
      title="My Dashboard"
      openSettings={openSettings}
      setOpenSettings={setOpenSettings}
    >
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiPaper-root": {
            // Targeting the Dialog's Paper component for the background
            backgroundColor: "#0A121E", // Example darker color; replace with your actual color
            color: "#fff", // Making text color white
            borderRadius: 2, // Optional: Adding a border radius
            border: "1px solid #007275", // Optional: Adding a border
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">{"Save Changes?"}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ color: "#fff" }}
          >
            Saving changes requires a reboot to apply. Do you want to proceed
            with saving settings?
          </DialogContentText>
          {offWarning && (
            <DialogContentText
              sx={{ color: "red", mt: 2 }} // Adjust the margin as needed
            >
              Warning: You have toggled the relay OFF. If you would like to
              purge your system please select the box on the left.
              {!isMobileScreen ? (
                <InfoToolTip
                  tooltipContent={
                    <>
                      <Typography>
                        Purging will reset the following settings:
                      </Typography>
                      <ul style={{ paddingLeft: "1.2em", margin: "0.5em 0" }}>
                        <li>Network Settings</li>
                        <li>Fingerprints</li>
                        <li>System Logs</li>
                      </ul>
                    </>
                  }
                />
              ) : (
                <InfoToolTipMobile
                  tooltipContent={
                    <>
                      <Typography>
                        Purging will reset the following settings:
                      </Typography>
                      <ul style={{ paddingLeft: "1.2em", margin: "0.5em 0" }}>
                        <li>Network Settings</li>
                        <li>Fingerprints</li>
                        <li>System Logs</li>
                      </ul>
                    </>
                  }
                />
              )}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: offWarning ? "space-between" : "flex-end",
          }}
        >
          {offWarning && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkboxState}
                  sx={{ color: "#03BDC5" }}
                  onChange={handlePurge}
                />
              }
              label="Purge"
              sx={{ color: "#03BDC5", marginLeft: 1 }}
            />
          )}
          <div>
            <Button onClick={handleDialogClose} sx={{ color: "#03BDC5" }}>
              Cancel
            </Button>
            <Button onClick={handleSave} sx={{ color: "#03BDC5" }}>
              Save
            </Button>
            {/* <Button
              onClick={handleSubmit}
              sx={{ color: "#03BDC5" }}
              autoFocus
            >
              Apply and Reboot
            </Button> */}
          </div>
        </DialogActions>
      </Dialog>

      <Box sx={{ width: "100%" }}>
        {/* Header */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            style={{ color: "#ffffff" }}
            marginBottom={0}
          >
            Relay Settings
          </Typography>

          {!isMobileScreen && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Paper
                elevation={0}
                sx={{
                  display: "flex",
                  //   border: '4px solid "#ffffff"',
                  flexWrap: "wrap",
                  backgroundColor: "#0A121EFF",
                  alignContent: "center",
                  borderWidth: "1px",
                  borderColor: "#0A121EFF",
                  maxWidth: "fit-content",
                  justifyContent: "center",
                  height: "2.5rem",
                  marginRight: "1rem",
                }}
              >
                <ToggleButtonGroup
                  ccolor="primary"
                  value={relayEnabled}
                  exclusive
                  onChange={handleToggleChange}
                  aria-label="Mode"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    border: 1,
                    borderRadius: 2,
                    // marginRight: "1rem",
                    borderColor: "#03BDC5",
                    // height: "40px",
                    // top: '1rem', // Add some space below the group
                    // marginBottom: '1rem', // Add some space below the group
                    "& .MuiToggleButtonGroup-grouped": {
                      borderRadius: 2, // Remove border-radius
                      //   border: 1, // Remove individual button borders
                      //   borderColor: '#595959', // Button border color
                      margin: "2px",
                      height: "35px",
                      "&.Mui-selected": {
                        backgroundColor: "#03BDC5", // Selected background color
                        color: "white", // Selected text color
                        "&:hover": {
                          backgroundColor: "#03BDC5", // Keep the same color on hover
                        },
                      },
                      "&:not(.Mui-selected)": {
                        // backgroundColor: 'grey', // Non-selected background color
                        color: "#b1e0f1ff", // Non-selected text color
                        "&:hover": {
                          backgroundColor: "#03BDC5", // Lighten the color on hover
                          transition: "0.2s", // Remove the hover transition
                        },
                      },
                    },
                  }}
                >
                  <ToggleButton value="off">Off</ToggleButton>
                  <ToggleButton value="on">On</ToggleButton>
                </ToggleButtonGroup>
              </Paper>

              <Divider
                flexItem
                orientation="vertical"
                sx={{ mx: 0.5, my: 1, backgroundColor: "teal" }}
              />

              <Button
                variant="contained"
                onClick={handleApplyClick}
                sx={{
                  bgcolor: "#03BDC5",
                  marginLeft: "1rem",
                  height: "40px",
                  width: "120px",
                }}
              >
                Apply
              </Button>
            </Box>
          )}
        </Box>

        {isMobileScreen && (
          <Box
            sx={{
              position: "fixed",
              zIndex: 2,
              bottom: 16,
              left: 0,
              right: 0,
              margin: "auto",
              width: "auto",
              maxWidth: "fit-content", // Prevents the box from touching the viewport edges
              backgroundColor: "#0a121e",
              borderRadius: 2,
              boxShadow: 3,
              border: 1,
              // p: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Paper
                elevation={0}
                sx={{
                  display: "flex",
                  //   border: '4px solid "#ffffff"',
                  flexWrap: "wrap",
                  backgroundColor: "#0A121EFF",
                  alignContent: "center",
                  borderWidth: "1px",
                  borderColor: "#0A121EFF",
                  maxWidth: "fit-content",
                  paddingX: "0.5rem",
                  justifyContent: "center",
                  height: "2.5rem",
                }}
              >
                <ToggleButtonGroup
                  ccolor="primary"
                  value={relayEnabled}
                  exclusive
                  onChange={handleToggleChange}
                  aria-label="Mode"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    border: 1,
                    borderRadius: 2,
                    marginRight: "1rem",
                    borderColor: "#03BDC5",
                    // height: "40px",
                    "& .MuiToggleButtonGroup-grouped": {
                      borderRadius: 2, // Remove border-radius
                      margin: "2px",
                      height: "35px",
                      "&.Mui-selected": {
                        backgroundColor: "#03BDC5", // Selected background color
                        color: "white", // Selected text color
                        "&:hover": {
                          backgroundColor: "#03BDC5", // Keep the same color on hover
                        },
                      },
                      "&:not(.Mui-selected)": {
                        // backgroundColor: 'grey', // Non-selected background color
                        color: "#b1e0f1ff", // Non-selected text color
                        "&:hover": {
                          backgroundColor: "#03BDC5", // Lighten the color on hover
                          transition: "0.2s", // Remove the hover transition
                        },
                      },
                    },
                  }}
                >
                  <ToggleButton value="off">Off</ToggleButton>
                  <ToggleButton value="on">On</ToggleButton>
                </ToggleButtonGroup>
              </Paper>

              <Divider
                flexItem
                orientation="vertical"
                sx={{ mx: 0.5, my: 1, backgroundColor: "teal" }}
              />

              <Button
                variant="contained"
                onClick={handleApplyClick}
                sx={{
                  bgcolor: "#03BDC5",
                  marginLeft: "1rem",
                  height: "40px",
                  width: "120px",
                }}
              >
                Apply
              </Button>
            </Box>
          </Box>
        )}

        {/* Subtitle */}
        <Typography
          variant="subtitle1"
          gutterBottom
          style={{ color: "#aaa" }}
          marginBottom={2}
        >
          Change your relay network settings here
        </Typography>

        {/* Grid Layout for Boxes */}

        <Grid container spacing={2} justifyContent="center">
          {" "}
          {/* Changed to 2 for spacing between items */}
          {/* Network Box */}
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Paper style={boxStyle}>
              <Typography variant="h5" gutterBottom>
                Relay
              </Typography>
              <CustomTextField
                fullWidth
                label="RELAY EXE" // Set the label text here
                variant="outlined"
                margin="normal"
                inputProps={{ maxLength: 49 }}
                value={relayExe}
                onChange={(e) => setRelayExe(e.target.value)}
                error={!!relayExeError}
                helperText={relayExeError}
                disabled={isRelayEnabled}
              />
              <CustomTextField
                fullWidth
                label="API CALL LOCATION" // Set the label text here
                variant="outlined"
                margin="normal"
                inputProps={{ maxLength: 49 }}
                value={relayRestart}
                error={!!relayRestartError}
                helperText={relayRestartError}
                onChange={(e) => setRelayRestart(e.target.value)}
                // disabled={isRelayEnabled}
              />
              <CustomTextField
                fullWidth
                label="NODE AUTHENTICATION ENDPOINT" // Set the label text here
                variant="outlined"
                margin="normal"
                inputProps={{ maxLength: 49 }}
                value={jsonPost}
                error={!!jsonPostError}
                helperText={jsonPostError}
                onChange={(e) => setJsonPost(e.target.value)}
                // disabled={isRelayEnabled}
              />
              <CustomTextField
                fullWidth
                label="IP PING ADDRESS"
                variant="outlined"
                margin="normal"
                value={ipPingAddress}
                error={!!ipPingAddressError}
                helperText={ipPingAddressError}
                onChange={(e) => setIpPingAddress(e.target.value)}
                // disabled={isRelayEnabled}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Paper style={boxStyle}>
              <Typography variant="h5" gutterBottom>
                Main Details
              </Typography>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "3fr 2fr",
                  gap: "10px",
                  alignItems: "start",
                }}
              >
                <CustomTextField
                  fullWidth
                  label="NICKNAME" // Set the label text here
                  variant="outlined"
                  margin="normal"
                  value={nickname}
                  onChange={(e) => setNickname(e.target.value)}
                  inputProps={{ maxLength: 19 }}
                  // disabled={isRelayEnabled}
                  error={!!error} // The TextField is marked as error if there's an error message
                  helperText={error} // Display the error message as helper text
                />
                <CustomTextField
                  fullWidth
                  label="PORT" // Set the label text here
                  variant="outlined"
                  margin="normal"
                  value={port}
                  error={!!portError}
                  helperText={portError}
                  inputProps={{ maxLength: 5 }}
                  onChange={(e) => setPort(e.target.value)}
                  // disabled={isRelayEnabled}
                />
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "3fr 2fr",
                  gap: "10px",
                  alignItems: "start",
                }}
              >
                <CustomTextField
                  fullWidth
                  label="EMAIL (Optional)" // Set the label text here
                  variant="outlined"
                  margin="normal"
                  value={email}
                  error={!!emailError}
                  inputProps={{ maxLength: 49 }}
                  helperText={emailError}
                  onChange={(e) => setEmail(e.target.value)}
                  // disabled={isRelayEnabled}
                />
                <CustomTextField
                  fullWidth
                  label="NFT ID (If applicable)" // Set the label text here
                  variant="outlined"
                  margin="normal"
                  value={nftID}
                  error={!!nftIDError}
                  helperText={nftIDError}
                  inputProps={{ maxLength: 4 }}
                  onChange={(e) => setNftID(e.target.value)}
                  // disabled={isRelayEnabled}
                />
              </Box>
              <CustomTextField
                fullWidth
                label="WALLET ID" // Set the label text here
                variant="outlined"
                margin="normal"
                value={walletId}
                error={!!walletIdError}
                helperText={walletIdError}
                inputProps={{ maxLength: 42 }}
                onChange={(e) => setWalletId(e.target.value)}
                // disabled={isRelayEnabled}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Paper style={boxStyle}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h5" gutterBottom>
                  Recurring Data Limit
                </Typography>

                {!isSmallScreen && (
                  <Paper
                    elevation={0}
                    // disabled={isRelayEnabled}
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      backgroundColor: "#0A121EFF",
                      alignContent: "center",
                      borderWidth: "1px",
                      borderColor: "#0A121EFF",
                      maxWidth: "fit-content",
                      alignItems: "center",
                      justifyContent: "center",
                      mt: 1,
                      height: "2rem",
                      opacity: isRelayEnabled ? 1 : 1,
                    }}
                  >
                    <ToggleButtonGroup
                      // disabled={isRelayEnabled}
                      ccolor="primary"
                      value={isAccountingEnabled}
                      exclusive
                      onChange={handleToggleChangeAccounting}
                      aria-label="Mode"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        border: 1,
                        borderRadius: 2,
                        borderColor: isRelayEnabled ? "#03BDC5" : "#03BDC5", // Conditionally set the borderColor
                        // height: "40px",
                        mb: 1,
                        "& .MuiToggleButtonGroup-grouped": {
                          borderRadius: 2,
                          margin: "2px",
                          height: "35px",
                          "&.Mui-selected": {
                            backgroundColor: isRelayEnabled
                              ? "#03BDC5"
                              : "#03BDC5", // Conditionally set the backgroundColor
                            color: isRelayEnabled ? "white" : "white",
                            "&:hover": {
                              backgroundColor: isRelayEnabled
                                ? "#03BDC5"
                                : "#03BDC5", // Keep the color on hover
                            },
                          },
                          "&:not(.Mui-selected)": {
                            color: isRelayEnabled ? "#b1e0f1ff" : "#b1e0f1ff",
                            "&:hover": {
                              backgroundColor: isRelayEnabled
                                ? "#03BDC5"
                                : "#03BDC5", // Conditionally set the hover color
                            },
                          },
                          "& .MuiSvgIcon-root": {
                            color: isRelayEnabled ? "#03BDC5" : "#03BDC5", // Conditionally set the icon color
                          },
                        },
                      }}
                    >
                      <ToggleButton value="enable">Enabled</ToggleButton>
                      <ToggleButton value="disable">Disabled</ToggleButton>
                    </ToggleButtonGroup>
                  </Paper>
                )}
              </Box>

              {isSmallScreen && (
                <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
                  <Paper
                    elevation={0}
                    // disabled={isRelayEnabled}
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      backgroundColor: "#0A121EFF",
                      alignContent: "center",
                      borderWidth: "1px",
                      borderColor: "#0A121EFF",
                      maxWidth: "fit-content",
                      alignItems: "center",
                      justifyContent: "center",
                      mt: 1,
                      height: "2rem",
                      opacity: isRelayEnabled ? 1 : 1,
                    }}
                  >
                    <ToggleButtonGroup
                      // disabled={isRelayEnabled}
                      ccolor="primary"
                      value={isAccountingEnabled}
                      exclusive
                      onChange={handleToggleChangeAccounting}
                      aria-label="Mode"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        border: 1,
                        borderRadius: 2,
                        borderColor: isRelayEnabled ? "#03BDC5" : "#03BDC5", // Conditionally set the borderColor
                        // height: "40px",
                        mb: 1,
                        "& .MuiToggleButtonGroup-grouped": {
                          borderRadius: 2,
                          margin: "2px",
                          height: "35px",
                          "&.Mui-selected": {
                            backgroundColor: isRelayEnabled
                              ? "#03BDC5"
                              : "#03BDC5", // Conditionally set the backgroundColor
                            color: isRelayEnabled ? "white" : "white",
                            "&:hover": {
                              backgroundColor: isRelayEnabled
                                ? "#595959"
                                : "#03BDC5", // Keep the color on hover
                            },
                          },
                          "&:not(.Mui-selected)": {
                            color: isRelayEnabled ? "#b1e0f1ff" : "#b1e0f1ff",
                            "&:hover": {
                              backgroundColor: isRelayEnabled
                                ? "#03BDC5"
                                : "#03BDC5", // Conditionally set the hover color
                            },
                          },
                          "& .MuiSvgIcon-root": {
                            color: isRelayEnabled ? "#03BDC5" : "#03BDC5", // Conditionally set the icon color
                          },
                        },
                      }}
                    >
                      <ToggleButton value="enable">Enabled</ToggleButton>
                      <ToggleButton value="disable">Disabled</ToggleButton>
                    </ToggleButtonGroup>
                  </Paper>
                </Box>
              )}

              {/* Accounting Max */}

              <Box sx={{ mt: isSmallScreen ? 2 : 1 }}>
                <Typography gutterBottom>MAX DATA LIMIT:</Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Slider
                    // disabled={
                    //   isRelayEnabled || isAccountingEnabled !== "enable"
                    // }
                    disabled={isAccountingEnabled !== "enable"}
                    value={
                      typeof accountingMax === "number" ? accountingMax : 15
                    }
                    onChange={(event, newValue) =>
                      handleSliderChange(setAccountingMax, 10000)(
                        event,
                        newValue
                      )
                    }
                    aria-labelledby="input-slider-accounting"
                    min={15}
                    max={10000}
                    sx={{
                      "& .MuiSlider-thumb": {
                        color: "#03BDC5", // Thumb color
                      },
                      "& .MuiSlider-track": {
                        color: "#03BDC5", // Track color
                      },
                      "& .MuiSlider-rail": {
                        color: "rgba(2, 190, 197, 0.3)", // Rail color
                      },
                      "&.Mui-disabled": {
                        "& .MuiSlider-thumb": {
                          color: "#595959", // Thumb color
                        },
                        "& .MuiSlider-track": {
                          color: "lightgrey",
                        },
                        "& .MuiSlider-rail": {
                          color: "lightgrey",
                        },
                      },
                      flex: 1,
                    }}
                  />
                  <CustomTextField
                    // disabled={isRelayEnabled || isAccountingEnabled !== "enable"}
                    disabled={isAccountingEnabled !== "enable"}
                    value={accountingMax.toLocaleString() + " GB"}
                    size="small"
                    onChange={(event) => {
                      // Directly handle the input change here to strip non-numeric characters and format
                      const value = event.target.value.replace(/[^\d]/g, ""); // Keep only digits
                      handleInputChange(
                        { target: { value } },
                        setAccountingMax,
                        10000,
                        15
                      );
                    }}
                    onBlur={() => handleBlur(setAccountingMax, accountingMax)}
                    inputProps={{
                      "aria-labelledby": "input-slider-accounting",
                    }}
                    sx={{ ml: 2, width: 120 }}
                    type="text" // Change type to text
                  />
                </Box>
              </Box>
              {/* Accounting Start */}
              <Box sx={{ mt: 1.5 }}>
                <Typography sx={{ mb: 1 }}>RESET EVERY:</Typography>
                <Select
                  // disabled={isRelayEnabled || isAccountingEnabled !== "enable"}
                  disabled={isAccountingEnabled !== "enable"}
                  value={accountingStart}
                  onChange={handleAccountingStartChange}
                  displayEmpty
                  fullWidth
                  size="small"
                  sx={{
                    color:
                      isRelayEnabled || isAccountingEnabled !== "enable"
                        ? "#595959"
                        : "white", // Adjust text color based on disabled state
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 177, 183, 0.72)", // Border color
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 177, 183, 0.72)", // Border color on hover
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 177, 183, 0.72)", // Border color when focused
                    },
                    "&.Mui-disabled": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#595959",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "#595959",
                      },
                    },
                    // This targets the text specifically
                    "& .MuiSelect-select.Mui-disabled": {
                      WebkitTextFillColor: "#595959",
                    },
                  }}
                  inputProps={{
                    "aria-label": "Without label",
                    style: {
                      color:
                        isRelayEnabled || isAccountingEnabled !== "enable"
                          ? "#595959"
                          : "white", // Ensure the dropdown arrow matches the text color
                    },
                  }}
                >
                  <MenuItem value="month">Month</MenuItem>
                  <MenuItem value="week">Week</MenuItem>
                  <MenuItem value="day">Day</MenuItem>
                </Select>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Paper style={boxStyle}>
              <Typography variant="h5" gutterBottom>
                Bandwidth Settings
              </Typography>
              <Box sx={{ mt: 1 }}>
                <Typography gutterBottom>BANDWIDTH RATE:</Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Slider
                    // disabled={isRelayEnabled}
                    value={
                      typeof bandWidthRate === "number" ? bandWidthRate : 2
                    }
                    onChange={(event, newValue) =>
                      handleSliderChange(setBandWidthRate, 1000)(
                        event,
                        newValue
                      )
                    }
                    aria-labelledby="input-slider-bandwidth"
                    min={2}
                    max={1000}
                    sx={{
                      "& .MuiSlider-thumb": {
                        color: "#03BDC5", // Thumb color
                      },
                      "& .MuiSlider-track": {
                        color: "#03BDC5", // Track color
                      },
                      "& .MuiSlider-rail": {
                        color: "rgba(2, 190, 197, 0.3)", // Rail color
                      },
                      "&.Mui-disabled": {
                        "& .MuiSlider-thumb": {
                          color: "#595959", // Thumb color
                        },
                        "& .MuiSlider-track": {
                          color: "lightgrey",
                        },
                        "& .MuiSlider-rail": {
                          color: "lightgrey",
                        },
                      },
                      flex: 1,
                    }}
                  />
                  <CustomTextField
                    value={bandWidthRate.toLocaleString() + " Mbits"}
                    size="small"
                    onChange={(event) => {
                      const value = event.target.value.replace(/[^\d]/g, ""); // Keep only digits
                      handleInputChange(
                        { target: { value } },
                        setBandWidthRate,
                        1000,
                        2
                      );
                    }}
                    onBlur={() => handleBlur(setBandWidthRate, bandWidthRate)}
                    inputProps={{
                      "aria-labelledby": "input-slider-bandwidth",
                    }}
                    sx={{ ml: 2, width: 120 }}
                    type="text" // Change type to text
                  />
                </Box>
                <Typography sx={{ color: "rgba(255, 255, 255, 0.7)" }}>
                  <span style={{ color: "#03BDC5" }}>Bandwidth Burst</span> will
                  be {Math.round(bandWidthRate * 1.2)} Mbits (20% greater than
                  Bandwidth rate)
                </Typography>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </MainContainer>
  );
};

export default RelaySettings;
