import React, { useState } from 'react';
import { Button, Typography, TextField, InputAdornment, IconButton, Tabs, Tab, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

const MultiFingerPrintInput = ({ onSave, onCancel }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [fingerprints, setFingerprints] = useState(['']);
  const [errors, setErrors] = useState(['']);
  const [bulkText, setBulkText] = useState('');
  const [bulkError, setBulkError] = useState('');

  const validateFingerprint = (fp) => {
    if (!fp) return "Fingerprint is required";
    if (!/^[a-fA-F0-9]{40}$/.test(fp)) {
      if (fp.length !== 40) return "Fingerprint must be 40 characters";
      return "Invalid Hex String";
    }
    return "";
  };

  const handleAdd = () => {
    setFingerprints([...fingerprints, '']);
    setErrors([...errors, '']);
  };

  const handleRemove = (index) => {
    const newFingerprints = fingerprints.filter((_, i) => i !== index);
    const newErrors = errors.filter((_, i) => i !== index);
    setFingerprints(newFingerprints);
    setErrors(newErrors);
  };

  const handleChange = (index, value) => {
    const newFingerprints = [...fingerprints];
    newFingerprints[index] = value;
    setFingerprints(newFingerprints);

    const newErrors = [...errors];
    newErrors[index] = validateFingerprint(value);
    setErrors(newErrors);
  };

  const handleBulkTextChange = (event) => {
    setBulkText(event.target.value);
    setBulkError('');
  };

  const handleBulkImport = () => {
    // Split by commas or newlines
    const rawFingerprints = bulkText
      .split(/[,\n]/)
      .map(fp => fp.trim())
      .filter(fp => fp.length > 0);

    // Validate all fingerprints
    const invalidFingerprints = rawFingerprints.filter(fp => validateFingerprint(fp));

    if (invalidFingerprints.length > 0) {
      setBulkError(`${invalidFingerprints.length} invalid fingerprint(s) found`);
      return;
    }

    if (rawFingerprints.length === 0) {
      setBulkError('No valid fingerprints found');
      return;
    }

    onSave(rawFingerprints);
  };

  const handleSave = () => {
    if (activeTab === 0) {
      const newErrors = fingerprints.map(validateFingerprint);
      setErrors(newErrors);

      if (newErrors.every(error => !error) && fingerprints.some(fp => fp)) {
        onSave(fingerprints.filter(fp => fp));
      }
    } else {
      handleBulkImport();
    }
  };

  return (
    <div className="flex flex-col">
      <Tabs 
        value={activeTab} 
        onChange={(e, newValue) => setActiveTab(newValue)}
        sx={{
          '& .MuiTab-root': { color: '#595959' },
          '& .Mui-selected': { color: '#03BDC5 !important' },
          '& .MuiTabs-indicator': { backgroundColor: '#03BDC5' },
          width: '100%'
        }}
      >
        <Tab label="MANUAL ENTRY" />
        <Tab label="BULK IMPORT" />
      </Tabs>

      {activeTab === 0 ? (
        // Manual Entry Tab
        <div className="flex flex-col gap-6 p-4">
          {fingerprints.map((fingerprint, index) => (
            <div key={index} className="flex-1 mb-2">
              <TextField
                fullWidth
                value={fingerprint}
                onChange={(e) => handleChange(index, e.target.value)}
                placeholder="Enter fingerprint"
                inputProps={{ maxLength: 40 }}
                InputProps={{ 
                  maxLength: 40,
                  style: { color: 'white', fontSize: '16px' },
                  endAdornment: fingerprints.length > 1 && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handleRemove(index)}
                        edge="end"
                        sx={{ 
                          color: '#ff4444',
                          '&:hover': {
                            color: '#ff6666'
                          }
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                sx={{
                  backgroundColor: '#0A121E',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'rgba(0, 177, 183, 0.72)',
                      borderWidth: '1px',
                    },
                    '&:hover fieldset': {
                      borderColor: '#03BDC5',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#03BDC5',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: 'white',
                    '&::placeholder': {
                      color: '#595959',
                      opacity: 1,
                    },
                  },
                }}
              />
              <div className="flex justify-between mt-1 mb-4">
                <Typography 
                  variant="caption" 
                  sx={{ 
                    color: fingerprint.length === 40 ? '#03BDC5' : '#ff4444',
                    fontSize: '0.75rem'
                  }}
                >
                  {fingerprint.length}/40
                </Typography>
                <Typography variant="caption">{'  '}</Typography>
                {errors[index] && (
                  <Typography 
                    variant="caption" 
                    sx={{ 
                      color: '#ff4444',
                      fontSize: '0.75rem'
                    }}
                  >
                    {errors[index]}
                  </Typography>
                )}
              </div>
            </div>
          ))}
          <div className="flex justify-end">
            <Button
              onClick={handleAdd}
              variant="outlined"
              startIcon={<AddIcon />}
              sx={{ 
                color: '#03BDC5',
                borderColor: '#03BDC5',
                borderWidth: '1px',
                '&:hover': {
                  borderColor: '#03BDC5',
                  borderWidth: '1px',
                  backgroundColor: 'rgba(3, 189, 197, 0.1)'
                }
              }}
            >
              ADD ANOTHER
            </Button>
          </div>
        </div>
      ) : (
        // Bulk Import Tab
        <div className="w-full p-4">
          <TextField
            multiline
            rows={6}
            fullWidth
            value={bulkText}
            onChange={handleBulkTextChange}
            placeholder="Paste fingerprints separated by commas or new lines"
            sx={{
              width: '100%',
              backgroundColor: '#0A121E',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'rgba(0, 177, 183, 0.72)',
                  borderWidth: '1px',
                },
                '&:hover fieldset': {
                  borderColor: '#03BDC5',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#03BDC5',
                },
              },
              '& .MuiInputBase-input': {
                color: 'white',
                '&::placeholder': {
                  color: '#595959',
                  opacity: 1,
                },
              },
            }}
          />
          <div className="mt-2">
            {bulkError && (
              <Typography 
                variant="caption" 
                sx={{ 
                  color: '#ff4444',
                  fontSize: '0.75rem',
                  display: 'block'
                }}
              >
                {bulkError}
              </Typography>
            )}
          </div>
        </div>
      )}

      <div className="flex gap-2 justify-between px-4 pb-4">
        <Button 
          onClick={onCancel} 
          sx={{ 
            color: '#03BDC5',
            '&:hover': {
              backgroundColor: 'rgba(3, 189, 197, 0.1)'
            }
          }}
        >
          CANCEL
        </Button>
        <Button 
          onClick={handleSave}
          sx={{ 
            color: '#03BDC5',
            '&:hover': {
              backgroundColor: 'rgba(3, 189, 197, 0.1)'
            }
          }}
          disabled={activeTab === 0 ? 
            (!fingerprints.some(fp => fp) || errors.some(error => error)) : 
            !bulkText.trim()
          }
        >
          SAVE
        </Button>
      </div>
    </div>
  );
};

export default MultiFingerPrintInput;